<template>
  <div class="groupbuy-list">
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'ThemeGroupbuyingList' }"
              >主題團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>主題團購列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-12 col-xl-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">主題團購列表</h4>
        </div>
        <div
          class="
            col-12 col-xl-6
            d-flex
            justify-content-start justify-content-xl-end
          "
        >
          <b-button
            v-if="checkPermission([consts.THEME_GROUPBUYING_CREATE])"
            class="mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="handleCreate"
            ><i class="fa fa-plus" aria-hidden="true"></i>新增主題開團</b-button
          >
        </div>
      </div>

      <div
        class="
          d-flex
          flex-wrap
          mb-3
          justify-content-start justify-content-lg-end
        "
      >
        <div class="col-md-3 mb-2 mb-md-0">
          <b-form-input
            v-model="query.keyword"
            placeholder="搜尋標題"
            @keyup.enter="getGroupbuyList"
          ></b-form-input>
        </div>

        <div class="col-md-2 mb-2 mb-md-0">
          <datepicker
            class="mr-2"
            v-model="query.start_at"
            bootstrap-styling
            format="yyyy-MM-dd"
            :language="zh"
            clear-button
          ></datepicker>
        </div>
        <div style="line-height: 45px">～</div>
        <div class="col-md-2 mb-2 mb-md-0">
          <datepicker
            v-model="query.end_at"
            bootstrap-styling
            format="yyyy-MM-dd"
            :language="zh"
            :disabledDates="disabledEndAtDates"
            clear-button
          ></datepicker>
        </div>
        <b-button @click="handleSearch"><i class="fa fa-search"></i></b-button>
      </div>

      <b-tabs v-model="currentTabIndex" @input="handleChangeTab">
        <b-tab v-for="tab in tabs" :title="tab.title" :key="tab.key">
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-table
                  striped
                  hover
                  responsive
                  :items="themeGroupbuyings"
                  :fields="fields"
                  :busy="isLoading"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #cell(groupbuying_number)="data">
                    <router-link
                      :to="{
                        name: 'ThemeGroupbuyingDetail',
                        params: { themeGroupbuyingId: data.item.id },
                      }"
                    >
                      {{ data.item.groupbuying_number }}
                    </router-link>
                  </template>
                  <template #cell(pic_url)="data">
                    <div class="list-image" v-if="data.item.pic_url">
                      <img :src="data.item.pic_url" alt="商品圖片" />
                    </div>
                  </template>
                  <template #cell(start_at)="data">
                    <div>
                      {{
                        format(new Date(data.item.start_at), "yyyy-MM-dd HH:mm")
                      }}
                    </div>
                  </template>
                  <template #cell(end_at)="data">
                    <div>
                      {{
                        format(new Date(data.item.end_at), "yyyy-MM-dd HH:mm")
                      }}
                    </div>
                  </template>
                  <template #cell(action)="data">
                    <b-button
                      v-if="
                        checkPermission([consts.THEME_GROUPBUYING_MODIFY]) &&
                        !['the_end', 'deleted'].includes(
                          tabs[currentTabIndex].key
                        )
                      "
                      variant="inverse-warning"
                      :to="{
                        name: 'ThemeGroupbuyingEdit',
                        params: { themeGroupbuyingId: data.item.id },
                      }"
                    >
                      編輯
                    </b-button>
                    <b-button
                      class="ml-1"
                      v-if="
                        checkPermission([consts.THEME_GROUPBUYING_MODIFY]) &&
                        !['in_progress', 'deleted'].includes(
                          tabs[currentTabIndex].key
                        )
                      "
                      variant="inverse-danger"
                      @click="showDeleteThemeWindow(data.item)"
                    >
                      刪除
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>

            <div
              class="d-flex justify-content-center align-items-center"
              style="margin-top: 80px"
            >
              <b-pagination
                class="separated mb-0"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                @change="handleChangePage"
              ></b-pagination>
              <div class="ml-2">
                每頁顯示數量<b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  style="width: 70px"
                  class="ml-2"
                  @change="handleChangePage"
                ></b-form-select>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import { BTable } from "bootstrap-vue";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import themeGroupbuyingApi from "@/apis/themeGroupbuying";
import "bootstrap-vue/dist/bootstrap-vue.css";
import checkPermission from "@/mixins/checkPermission";

export default {
  mixins: [checkPermission],
  components: {
    BTable,
    Datepicker,
  },
  data() {
    return {
      zh,
      isLoading: false,
      themeGroupbuyings: [],
      tabs: [
        {
          key: "preparing",
          title: "準備中",
        },
        {
          key: "in_progress",
          title: "開團中",
        },
        {
          key: "the_end",
          title: "已完成",
        },
        {
          key: "deleted",
          title: "已刪除",
        },
      ],
      fields: [
        {
          key: "groupbuying_number",
          label: "團購 ID",
        },
        {
          key: "title",
          label: "標題",
        },
        {
          key: "pic_url",
          label: "圖片",
        },
        {
          key: "start_at",
          label: "開始時間",
        },
        {
          key: "end_at",
          label: "結束時間",
        },
        {
          key: "action",
          label: "操作",
        },
      ],
      perPageOptions: [10, 50, 100],
      currentTab: "",
      currentTabIndex: 0,
      perPage: 10,
      currentPage: 1,
      total: 0,
      query: {
        keyword: "",
        start_at: null,
        end_at: null,
      },
    };
  },
  computed: {
    disabledEndAtDates() {
      return { to: new Date(this.query.start_at) };
    },
  },

  created() {
    const { keyword, tab } = this.$route.query || {};
    this.currentTabIndex = this.tabs.findIndex((t) => t.key == tab);
    this.currentTabIndex =
      this.currentTabIndex == -1 ? 0 : this.currentTabIndex;
    if (keyword) {
      this.query.keyword = keyword;
    }
    this.getGroupbuyList();
  },

  methods: {
    format,
    async getGroupbuyList() {
      try {
        this.isLoading = true;
        const { data } = await themeGroupbuyingApi.getThemeGroupbuyings({
          status: this.tabs[this.currentTabIndex].key,
          page: this.currentPage,
          per_page: this.perPage,
          ...this.query,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.themeGroupbuyings = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.currentPage = 1;
      this.getGroupbuyList();
    },
    handleCreate() {
      this.$router.push({ name: "ThemeGroupbuyingCreate" });
    },
    handleChangeTab() {
      this.currentPage = 1;
      this.$router.push({
        query: { tab: this.tabs[this.currentTabIndex].key },
      });
      this.getGroupbuyList();
    },
    handleChangePage() {
      this.$nextTick(() => {
        this.getGroupbuyList();
      });
    },
    showDeleteThemeWindow(themeGroupbuying) {
      this.$swal({
        title: "確定要刪除嗎？",
        type: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "確定刪除",
        cancelButtonText: "返回",
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.deleteThemeGroupbuying(themeGroupbuying);
        }
      });
    },
    async deleteThemeGroupbuying(themeGroupbuying) {
      let response = await themeGroupbuyingApi.removeThemeGroupbuying(
        themeGroupbuying
      );
      if (response.status === 200) {
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "成功刪除",
        });
        this.getGroupbuyList();
      } else {
        if (response.data.message) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: response.data.message,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.popover {
  max-width: 600px;
}

.groupbuy-list {
  .nav-tabs .nav-link.active,
  .table thead {
    background: rgba(255, 98, 88, 0.2);
  }
}

.list-image {
  img {
    border-radius: 0 !important;
    max-width: 60px !important;
    max-height: 45px !important;
    display: block !important;
    width: auto !important;
    height: auto !important;
  }
}
</style>
